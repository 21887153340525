@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~font-awesome/css/font-awesome.min.css';
@import '~leaflet/dist/leaflet.css';

$tan: #D2B48C;
$ang_lightblue: #4DAAE8;
$ang_blue: #314A6D;

html, body {
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: "Garamond", serif;
}

hr {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border: 0;
  height: 1px;
  background-color: black;
  width: 100px;
}
