@import '../globals.scss';

* {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", sans-serif;
}

.debug {
  position: fixed;
  top: 0;
  left: .5em;
  z-index: 9999;
  background: rgba(0, 0, 0, .85);
  color: #fff;
  padding: .5em;
  border-radius: 0 0 5px 5px;
}

#group1 {
  position: absolute;
  height: 100%;
  padding: 0;
  margin: 0;
}
#group2 {
  z-index: 5;
}
.parallax__layer__back {
  // 1 + (translateZ * -1) / perspective
  transform: translateZ(-600px) scale(3);
  z-index: 2;
}

.parallax__layer__back {
  // 1 + (translateZ * -1) / perspective
  transform: translateZ(-300px) scale(2);
  z-index: 3;
}

.parallax__layer__top {
  transform: translateZ(0);
  z-index: 4;
}

.parallax {
  perspective: 300px;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

.debug-on .parallax__group {
  -webkit-transform: translate3d(800px, 0, -800px) rotateY(30deg);
  transform: translate3d(700px, 0, -800px) rotateY(30deg);
}

.debug-on .parallax__layer {
  box-shadow: 0 0 0 2px #000;
  opacity: 0.9;
}
.parallax__group {
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
}

.parallax__group {
  position: relative;
  height: 100vh;
  transform-style: preserve-3d;
}

.parallax__layer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}


.jumbotron .jumbotron-fluid {
  text-align: center;
  background-color: #f3f3f3;
  margin: 0 auto;

  h1 {
    font-size: 30px;
  }



  table {
    max-width: 800px;
    margin: 0 auto;
    tr {
      td {
        border: 0;
        width: 300px;
      }
      td.text-large {
        font-size: 20px;
      }
      td.text-small {
        font-size: 10pt;
        text-align: justify;
      }
    }
  }
}

.header-background {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  background: url("/assets/header.jpg") no-repeat center top;
  background-size: cover;
  filter: grayscale(90%);
  z-index: -2;
}

.full-wrapper {
  height: 100vh;
  width: 100vw;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;

  .header-content {
    display: flex;
    flex-direction: column;
    height: 50vh;
    width: 100vw;
    margin: 0 auto;
    align-items: center;
    justify-content: space-around;

    h2 {
      font-size: 20pt;
      color: white;
      padding-bottom: 50px;
    }

    .slogan-wrapper {
      margin-bottom: 20px;
      padding: 30px;
      background: rgba(255, 255, 255, 0.5);
      h1 {
        margin: 0 auto;
        padding: 0;
        font-size: 36pt;
        font-family: "Helvetica Neue", sans-serif;
      }
    }
  }
}




.screen-wrapper-content {
  position: absolute;
  height: 100%;
  width: 100%;
}


